// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $tablet-width) {
    .widget {

        // Hero Banner

        &-banner-image-wrapper {
            &.small,
            &.medium,
            &.large {
                height: auto;
            }
        }
    }
}
