// =============================================================================
//
//  Page Designer Widgets Variables
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// General

$widget-assets-full__width: 100%;
$widget-assets-full__height: 100%;
$p__line-height: 1.4;

// Hero Banner Widget

$widget-banner-label__min-width: 6.5rem;
$widget-banner-label__padding: ($base-padding * 0.3) ($base-padding * 0.5);
$widget-banner-label__font-weight: $bolder;
$widget-banner-label-after__border: 0.5rem solid transparent;
$widget-banner-label-after__margin-bottom: -0.5rem;
$widget-banner-label__min-width--large: 14.3rem;
$widget-banner-label__font-size--large: 2.3rem;
$widget-banner-label__line-height--large: 1.2;
$widget-banner-label__padding--large: ($base-padding * 0.3) ($base-padding * 0.7);
$widget-banner-label-after__border-width--large: 1rem;
$widget-banner-label-after__margin-bottom--large: -1rem;
$widget-banner-label__border-radius: $border * 2;
$widget-hero-banner-full__width: 100%;
$widget-hero-banner-full__height: 100%;
$widget-hero-banner-content-half__width: 60%;
$widget-hero-banner-content-half__width--large: 50%;
$widget-hero-banner-mobile-large__height: 25.5rem;
$widget-hero-banner-mobile-medium__height: 18.6rem;
$widget-hero-banner-mobile-small__height: 16.5rem;
$widget-hero-banner-mobile-large__height--medium: 40rem;
$widget-hero-banner-mobile-medium__height--medium: 35rem;
$widget-hero-banner-mobile-small__height--medium: 40rem;
$widget-hero-banner-content-wrapper__padding: $base-padding * 1.2;
$widget-hero-large-banner-content-wrapper__padding: ($base-padding * 5) ($base-padding * 1.2) ($base-padding * 3.5);
$widget-hero-large-banner-content-wrapper__padding--large: ($base-padding * 7.8) 0 ($base-padding * 7.4) ($base-padding * 5);
$widget-hero-banner-content-wrapper__padding--large: ($base-padding * 8) 0 ($base-padding * 5) ($base-padding * 5);
$widget-hero-banner-four-column-content-wrapper__padding--large: ($base-padding * 3.3) ($base-padding * 4) 0;
$widget-hero-banner-four-column-content-wrapper__padding--medium-large: ($base-padding * 2.3) ($base-padding * 4) 0;
$widget-hero-banner-category-matrix-content-wrapper__padding--large: ($base-padding * 3) 0 ($base-padding * 4) ($base-padding * 3);
$widget-hero-banner-category-matrix-content-wrapper__padding--medium-large: ($base-padding * 1.5) 0 ($base-padding * 4) ($base-padding * 3);
$widget-hero-banner-single-column-content-wrapper__padding--large: ($base-padding * 4) 0 ($base-padding * 5) ($base-padding * 5);
$widget-banner-button-wrapper__margin-top--medium-large: $base-margin;
$widget-hero-banner-content-wrapper__background-color: rgba($white, 0.8);
$widget-hero-banner-brand-text__color: $black-two;
$widget-hero-banner-brand-text__margin_top: $base-margin * 1.2;
$widget-hero-banner-text-headline__margin-top: $base-margin / 2;
$widget-hero-banner-text-headline__margin-top--large: $base-margin * 2;
$widget-hero-banner-four-column-text-headline__margin-top--large: $base-margin * 3.5;
$widget-hero-banner-four-column-text-headline__margin-top--medium-large: $base-margin * 2;
$widget-hero-banner-text-headline__line-height: 1;
$widget-hero-banner-button-wrapper__margin-top: $base-margin;
$widget-hero-banner-button__padding: ($base-padding * 0.5) ($base-padding * 1.5);
$widget-hero-banner-button__padding--large: ($base-padding * 1.25) 0;
$widget-hero-banner-four-column-button__padding--large: ($base-padding * 0.75) 0;
$widget-hero-banner-button__font-weight: $bolder;
$widget-hero-banner-button__width--large: 35.5rem;
$widget-hero-banner-small-image-button__width--large: 26.6rem;
$widget-hero-banner-small-image-button__padding--large: ($base-padding * 0.75) 0;
$widget-hero-banner-four-column-button__width--large: 26.1rem;
$widget-hero-banner-four-column-button__width--medium-large: 17.1rem;
$widget-hero-banner-four-column-button__margin-top--large: 1.4rem;
$widget-hero-banner-cat-matrix-button-big__width--large: 24.8rem;
$widget-hero-banner-cat-matrix-button-small__width--large: 19.4rem;
$widget-hero-banner-cat-matrix-button__padding--large: ($base-padding * 0.75) 0;
$widget-hero-banner-button-hover-black__background-color: $greyish-brown;
$widget-hero-banner-button-hover-yellow__background-color: $light-gold;
$widget-hero-banner-brand-image-wrapper__width: 6.4rem;
$widget-hero-banner-brand-image-wrapper__height: 6.4rem;
$widget-hero-banner-four-column-brand-image-wrapper__width: 5.7rem;
$widget-hero-banner-four-column-brand-image-wrapper__height: 5.7rem;
$widget-hero-banner-brand-image-wrapper-position__right: 1.5rem;
$widget-hero-banner-brand-image-wrapper-position__top: 1.5rem;
$widget-hero-banner-text-description__margin: 0  0 ($base-margin * 1.6);
$widget-hero-banner-four-column-text-description__margin--large: ($base-margin * 0.3)  0 0;


// Video Block Widget

$widget-video-block-full__width: 100%;
$widget-video-block-full__height: 100%;
$widget-video-responsive__pading-top: 56.26%;   /* By using percentage, it will calculate the padding
                                                to use based on the width of the element.
                                                In our example, we want to keep the ratio of 56.26% (height 9 ÷ width 16)
                                                because this is the default ratio for YouTube videos/ */

// Category Tile Widget

$widget-category-tile-full__width: 100%;
$widget-category-heading-wrapper__padding: $base-padding * 1.7;
$widget-category-heading-wrapper__padding--large: ($base-padding * 1.3) ($base-padding * 1.7) ($base-padding * 1.3) ($base-padding * 2.2);
$widget-category-heading-text__font-weight: $bolder;
$widget-subcategories__margin: ($base-margin * 0.7) 0 ($base-margin * 2);
$widget-subcategories__margin--large: ($base-margin * 1.6) 0;
$widget-subcategory__padding-bottom: $base-padding;
$widget-subcategory__color: $black-two;
$widget-subcategory-link__padding-left: $base-padding * 1.7;
$widget-subcategory-link__padding-left--large: $base-padding * 2.2;

// Affiliate Tile Widget 

$widget-affiliate-tile-wrapper__padding: $base-padding * 0.5;
$widget-affiliate-tile-wrapper__border: $border solid #ddd;
$widget-affiliate-tile-wrapper__margin: $base-margin;
$widget-affiliate-tile-wrapper__border-radius: 0.5rem;

$widget-affiliate-heading-text__margin: $base-margin 0;
$widget-affiliate-heading-text__font-weight: normal;


// Article Tile Widget

$widget-image-hover-overlay__background-color: rgba($black, 0.15);
$widget-article-date__color: $brown-grey;
$widget-article-link__margin-bottom: $base-margin * 1.2;
$widget-article-date__margin-top: $base-margin * 1.7;
$widget-article-categories__color: #646464;
$widget-article-categories__font-family: $montserrat;
$widget-article-categories__font-weight: $bold;
$widget-article-categories__margin: 0 ($base-margin * 0.8);
$widget-article-categories-separator-after__height: 1.2rem;
$widget-article-categories-separator-after__width: 0.1rem;
$widget-article-categories-separator-after__right: - 0.1rem;
$widget-article-title__margin: ($base-margin * 0.7) 0 ($base-margin * 0.6);
$widget-article-title__width: 60%;
$widget-article-title__width--large: 100%;
$widget-article-elements-hover__color: $brown-grey-two;
$widget-article-content__padding: 0 ($base-padding * 2);

// Goal Banner Widget

$widget-goal-banner-background-wrapper__height: 30.5rem;
$widget-goal-banner-background-wrapper__height--large: 25.8rem;
$widget-goal-banner-title__margin: ($base-margin * 3) auto ($base-margin * 6.5);
$widget-goal-banner-title__width: 50%;
$widget-goal-banner-title__margin--large: ($base-margin * 4.6) auto ($base-margin * 8.4);
$widget-goal-banner-title__width--large: 100%;
$widget-goal-banner-link__margin-bottom: $base-margin * 2.3;
$widget-goal-banner-link__font-weight--large: normal;
$widget-goal-banner-link__color: $white;
$widget-goal-banner-link__pading-right--large: $base-padding * 2.5;
$widget-goal-banner-diagonal: -184deg;
$widget-layout-goal-banner-gradient-background-percentage__first: 39.7%;
$widget-layout-goal-banner-gradient-background-percentage__second: 40%;
$widget-layout-goal-banner-gradient-background-percentage__first--large: 48.7%;
$widget-layout-goal-banner-gradient-background-percentage__second--large: 49%;

// Html Block Widget

$widget_hmtl_p__line-height: 1.4;
$widget-html-block__margin-bottom: $base-margin * 1.5;
$widget-html-block-icon__color: $red;
$widget-html-block-icon__font-size: 2.2rem;
$widget-html-block-icon__font-size--large: 3.2rem;

$copy-to-clipboard-arrow__position: absolute;
$copy-to-clipboard-arrow__bottom: -1.6rem;
$copy-to-clipboard-arrow__left: 3rem;
$copy-to-clipboard-arrow__width: 1rem;
$copy-to-clipboard-arrow__height: 0.5rem;
$copy-to-clipboard-arrow__margin: $base-margin * 0 $base-margin * 0.3;
$copy-to-clipboard-arrow--before__border-width: $border * 0 $border * 10 $border * 15;
$copy-to-clipboard-arrow--before__border-style: solid;
$copy-to-clipboard-arrow--before__border-color: transparent;
$copy-to-clipboard-arrow--before__border-bottom-color: rgba($dark-cream, 0.8);
$copy-to-clipboard-arrow--after__bottom: 0.1rem;
$copy-to-clipboard-arrow--after__border-bottom-color: rgba($dark-cream, 0.8);
