// =============================================================================
//
//  Page Designer Dynamic Options CSS
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@media screen and (min-width: $desktop-width) {
    .pd-color-white {
        &.pd-mobile-color-white {
            color: $white !important;
        }
        
        &.pd-mobile-color-black {
            color: $white !important;
        }

        &.pd-mobile-color-blackout {
            color: $white !important;
        }
        
        &.pd-mobile-color-orange {
            color: $white !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $white !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $white !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $white !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $white !important;
        }
        
        &.pd-mobile-color-grey {
            color: $white !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $white !important;
        }
    }
    
    .pd-color-black {
        &.pd-mobile-color-white {
            color: $black !important;
        }
        
        &.pd-mobile-color-black {
            color: $black !important;
        }

        &.pd-mobile-color-blackout {
            color: $black !important;
        }
        
        &.pd-mobile-color-orange {
            color: $black !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $black !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $black !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $black !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $black !important;
        }
        
        &.pd-mobile-color-grey {
            color: $black !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $black !important;
        }
    }

    .pd-color-blackout {
        &.pd-mobile-color-white {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-black {
            color: $blackout !important;
        }

        &.pd-mobile-color-blackout {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-orange {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-grey {
            color: $blackout !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $blackout !important;
        }
    }
    
    .pd-color-orange {
        &.pd-mobile-color-white {
            color: $orange !important;
        }
        
        &.pd-mobile-color-black {
            color: $orange !important;
        }

        &.pd-mobile-color-blackout {
            color: $orange !important;
        }
        
        &.pd-mobile-color-orange {
            color: $orange !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $orange !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $orange !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $orange !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $orange !important;
        }
        
        &.pd-mobile-color-grey {
            color: $orange !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $orange !important;
        }
    }
    
    .pd-color-orange-dash {
        &.pd-mobile-color-white {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-black {
            color: $orange-dash !important;
        }

        &.pd-mobile-color-blackout {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-orange {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-grey {
            color: $orange-dash !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $orange-dash !important;
        }
    }

    .pd-color-red-signal {
        &.pd-mobile-color-white {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-black {
            color: $red-signal !important;
        }

        &.pd-mobile-color-blackout {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-orange {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-grey {
            color: $red-signal !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $red-signal !important;
        }
    }

    .pd-color-ice-blue {
        &.pd-mobile-color-white {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $ice-blue !important;
        }

        &.pd-mobile-color-blackout {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-orange {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $ice-blue !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $ice-blue !important;
        }
    }

    .pd-color-dark-blue {
        &.pd-mobile-color-white {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-black {
            color: $dark-blue !important;
        }

        &.pd-mobile-color-blackout {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-orange {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-grey {
            color: $dark-blue !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $dark-blue !important;
        }
    }

    .pd-color-grey {
        &.pd-mobile-color-white {
            color: $grey !important;
        }
        
        &.pd-mobile-color-black {
            color: $grey !important;
        }

        &.pd-mobile-color-blackout {
            color: $grey !important;
        }
        
        &.pd-mobile-color-orange {
            color: $grey !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $grey !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $grey !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $grey !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $grey !important;
        }
        
        &.pd-mobile-color-grey {
            color: $grey !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $grey !important;
        }
    }

    .pd-color-light-grey {
        &.pd-mobile-color-white {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-black {
            color: $light-grey !important;
        }

        &.pd-mobile-color-blackout {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-orange {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-orange-dash {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-red-signal {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-ice-blue {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-dark-blue {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-grey {
            color: $light-grey !important;
        }
        
        &.pd-mobile-color-light-grey {
            color: $light-grey !important;
        }
    }

    // Page Designer Dynimic Font Sizes for Desktop

    // stylelint-disable selector-class-pattern

    .pd-font-size-22px:not(.widget-html-block) {
        @include get-font('medium-big');

        > * {
            @include get-font('medium-big');
        }
    }

    .pd-font-size-24px:not(.widget-html-block) {
        @include get-font('almost-big');

        > * {
            @include get-font('almost-big');
        }
    }

    .pd-font-size-25px:not(.widget-html-block) {
        @include get-font('big');

        > * {
            @include get-font('big');
        }
    }

    .pd-font-size-26px:not(.widget-html-block) {
        @include get-font('large');

        > * {
            @include get-font('large');
        }
    }

    .pd-font-size-30px:not(.widget-html-block) {
        @include get-font('xlarge');

        > * {
            @include get-font('xlarge');
        }
    }

    .pd-font-size-32px:not(.widget-html-block) {
        @include get-font('huge');

        > * {
            @include get-font('huge');
        }
    }

    .pd-font-size-36px:not(.widget-html-block) {
        @include get-font('enormous');
        line-height: 1.1;

        > * {
            @include get-font('enormous');
            line-height: 1.1;
        }
    }

    .pd-font-size-46px:not(.widget-html-block) {
        @include get-font('gigantic');
        line-height: 1.1;

        > * {
            @include get-font('gigantic');
            line-height: 1.1;
        }
    }

    .widget {
        &-html-block {
            &.pd-font-size-22px {
                > p {
                    @include get-font('medium-big');
                }
            }
        
            &.pd-font-size-24px {
                > p {
                    @include get-font('almost-big');
                }
            }
        
            &.pd-font-size-25px {
                > p {
                    @include get-font('big');
                }
            }
        
            &.pd-font-size-26px {
                > p {
                    @include get-font('large');
                }
            }
        
            &.pd-font-size-30px {
                > p {
                    @include get-font('xlarge');
                }
            }
        
            &.pd-font-size-32px {
                > p {
                    @include get-font('huge');
                }
            }
        
            &.pd-font-size-36px {
                > p {
                    @include get-font('enormous');
                    line-height: 1.1;
                }
            }
        
            &.pd-font-size-46px {
                > p {
                    @include get-font('gigantic');
                    line-height: 1.1;
                }
            }
        }

        // Goald banner Desktop Dynamic Background Colors

        &-goal-banner-background-wrapper {
            &.pd-diagonal-background-white {
                .pd-diagonal-background-white {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $white;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-black {
                .pd-diagonal-background-white {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $black;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-blackout {
                .pd-diagonal-background-white {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $blackout;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-orange {
                .pd-diagonal-background-white {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $orange;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-orange-dash {
                .pd-diagonal-background-white {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $orange-dash;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-red-signal {
                .pd-diagonal-background-white {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $red-signal;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-grey {
                .pd-diagonal-background-white {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-light-grey {
                .pd-diagonal-background-white {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $light-grey;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-ice-blue {
                .pd-diagonal-background-white {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $ice-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
    
            &.pd-diagonal-background-dark-blue {
                .pd-diagonal-background-white {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $white $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-black {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $black $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-blackout {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $blackout $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-orange-dash {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-red-signal {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-grey {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-light-grey {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-ice-blue {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
    
                .pd-diagonal-background-dark-blue {
                    background-color: $dark-blue;
                    background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first--large, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second--large);
                }
            }
        }
    }

}
