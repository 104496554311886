// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// stylelint-disable property-no-unknown

@import '../../mixins/image';
@import '../../themes/default/components/widgets/assets';

.widget {

    // General

    &-image {
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        object-fit: cover;
        object-position: var(--focal-point-x) var(--focal-point-y);
    }

    // Hero Banner

    &-banner-image-wrapper {
        background-color: $very-light-pink;

        &.hide-bgd-color {
            background-color: transparent;
        }
    }

    &-hero-banner-container {
        position: relative;
    }

    &-banner-label {
        @include get-font('micro');
        position: relative;
        z-index: 1;
        min-width: $widget-banner-label__min-width;
        padding: $widget-banner-label__padding;
        border-bottom-right-radius: $widget-banner-label__border-radius;
        font-weight: $widget-banner-label__font-weight;
        text-align: center;
        text-transform: uppercase;

        &.club-deal-badge {
            padding: 0;
            max-width: 10rem;

            img {
                max-width: 100%;
            }
        }
    }

    &-banner-content-wrapper-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-hero-banner-full__width;
        height: $widget-hero-banner-full__height;
        padding: $widget-hero-banner-content-wrapper__padding;

    }

    &-single-column {
        .widget-hero-banner-container.large {
            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-large-banner-content-wrapper__padding;
            }
        }
    }

    &-banner-content-wrapper {
        @include flexbox();
        @include align-items(flex-start);
        @include flex-direction(column);
        width: $widget-hero-banner-full__width;
        height: $widget-hero-banner-full__height;

        &.banner-content-backround-cover {
            background-color: $widget-hero-banner-content-wrapper__background-color;
        }

        &.center {
            text-align: center;

            .widget-banner-button-wrapper {
                @include justify-content(center);
            }
        }

        &.banner-content-half-width {
            .widget-banner-text-headline,
            .widget-banner-text-description,
            .widget-brand-link {
                width: $widget-hero-banner-content-half__width;
            }
        }

        .widget-banner-text-description {
            &.banner-content-quarter-width {
                width: $widget-hero-banner-full__width / 4 !important;
            }
        }
    }

    &-brand-link {
        @include get-font('mini');
        position: relative;
        z-index: 1;
        margin-top: $widget-hero-banner-brand-text__margin_top;
        color: $widget-hero-banner-brand-text__color;
        font-family: $montserrat;
        text-decoration: none;
    }

    &-banner-text-headline {
        margin-top: $widget-hero-banner-text-headline__margin-top;
    }

    &-banner-button-wrapper {
        @include flexbox();
        @include align-items(flex-end);
        position: relative;
        z-index: 1;
        margin-top: auto;
    }

    &-banner-button {
        @include get-font('xsmall-small');
        padding: $widget-hero-banner-button__padding;
        font-weight: $widget-hero-banner-button__font-weight;
        text-align: center;
        text-decoration: none;

        &.pd-background-black {
            &:hover {
                background-color: $widget-hero-banner-button-hover-black__background-color !important;
            }
        }
    }

    &-banner-global-link {
        position: relative;
        z-index: 1;
        width: $widget-assets-full__width;
        text-decoration: none;

        .widget-image {
            display: block;
        }
    }

    &-banner-hiden-link {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
    }

    // Video Block

    &-video-block-container {
        position: relative;
        overflow: hidden;
        padding-top: $widget-video-responsive__pading-top;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: $widget-video-block-full__width;
            height: $widget-video-block-full__height;
            border: 0;
        }
    }

    // Category Tile Widget

    &-category-main-wrapper {
        position: relative;
        background-color: $very-light-pink;
    }

    &-category-heading-wrapper {
        @include flexbox();
        @include align-items(center);
        @include justify-content(space-between);
        position: absolute;
        bottom: 0;
        left: 0;
        width: $widget-category-tile-full__width;
        padding: $widget-category-heading-wrapper__padding;
    }

    &-category-heading-text {
        @include get-font('small');
        font-weight: $widget-category-heading-text__font-weight;
        text-transform: capitalize;
        word-break: break-word;
    }

    &-subcategories {
        padding: 0;
        margin: $widget-subcategories__margin;
        list-style-type: none;
    }

    &-subcategory {
        @include get-font('small');
        @include flexbox();
        @include align-items(center);
        padding-bottom: $widget-subcategory__padding-bottom;
        color: $widget-subcategory__color;
        font-family: $montserrat;
        text-transform: capitalize;
        word-break: break-word;

        &:last-child {
            padding-bottom: 0;
        }

        > .widget-category-tile-link {
            @include get-font('small');
            padding-left: $widget-subcategory-link__padding-left;
            text-decoration: underline;
        }
    }

    // Article Tile Widget

    &-article-tile-top-content-wrapper,
    &-article-tile-bottom-content-wrapper {
        padding: $widget-article-content__padding;
    }

    &-article-link-wrapper {
        display: block;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        margin-bottom: $widget-article-link__margin-bottom;
        text-decoration: none;

        &:hover {
            color: inherit;

            .widget-image-hover-overlay {
                background-color: $widget-image-hover-overlay__background-color;;
            }

            .widget-article-title {
                color: $widget-article-elements-hover__color;
            }
        }
    }

    &-article-tile-image-wrapper {
        position: relative;
        background-color: $very-light-pink;

        &.hide-bgd-color {
            background-color: transparent;
        }
    }

    &-image-hover-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        background-color: transparent;
        transition: background-color 0.3s ease;
    }

    &-article-description {
        > p {
            margin: 0;
        }
    }

    &-article-date {
        @include get-font('xsmall');
        display: block;
        margin-top: $widget-article-date__margin-top;
        color: $widget-article-date__color;
    }

    &-article-title {
        @include get-font('compact');
        width: $widget-article-title__width;
        margin: $widget-article-title__margin;
        transition: color 0.3s ease;
    }

    &-article-categories {
        @include get-font('xsmall');
        color: $widget-article-categories__color;
        font-family: $widget-article-categories__font-family;
        font-weight: $widget-article-categories__font-weight;
        text-decoration: none;

        &::after {
            content: '';
            position: relative;
            right: $widget-article-categories-separator-after__right;
            display: inline-block;
            width: $widget-article-categories-separator-after__width;
            height: $widget-article-categories-separator-after__height;
            margin: $widget-article-categories__margin;
            background-color: $widget-article-categories__color;
            vertical-align: middle;
        }

        &:last-child {
            &::after {
                content: none;
            }
        }
    }

    // Affiliate Widget

    &-affiliate-tile-wrapper {
        padding: $widget-affiliate-tile-wrapper__padding;
        border: $widget-affiliate-tile-wrapper__border;
        border-radius: $widget-affiliate-tile-wrapper__border-radius;
        margin: $widget-affiliate-tile-wrapper__margin;
        text-align: center;

        .widget-image {
            display: inline-block;
            width: auto;
            max-width: 100%;
            height: auto;
        }
    }

    &-affiliate-heading-text {
        margin: $widget-affiliate-heading-text__margin;
        font-weight: $widget-affiliate-heading-text__font-weight;
    }

    &-affiliate-url-wrapper {
        word-break: break-all;

        a {
            line-height: 1.5;
            text-decoration: none;
        }
    }

    // Goal Banner Widget

    &-goal-banner-container {
        position: relative;
    }

    &-goal-banner-content {
        position: absolute;
        top: 0;
        left: 0;
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
        text-align: center;
    }

    &-goal-banner-background-inner-wrapper {
        width: $widget-assets-full__width;
        height: $widget-assets-full__height;
    }

    &-goal-banner-background-wrapper {
        height: $widget-goal-banner-background-wrapper__height;
    }

    &-goal-banner-title {
        @include get-font('large');
        width: $widget-goal-banner-title__width;
        margin: $widget-goal-banner-title__margin;
        color: $white;
    }

    &-goal-banner-link {
        @include get-font('compact');
        display: block;
        margin-bottom: $widget-goal-banner-link__margin-bottom;
        color: $widget-goal-banner-link__color;
        text-decoration-color: $widget-goal-banner-link__color;

        &:hover,
        &:active,
        &:focus {
            color: $widget-goal-banner-link__color;
            font-weight: $bolder;
            text-decoration-color: $widget-goal-banner-link__color;
        }
    }

    // Html Block

    &-html-block {
        &.center {
            text-align: center;
        }

        &.right {
            text-align: right;
        }

        &::before {
            color: $widget-html-block-icon__color;
            font-size: $widget-html-block-icon__font-size;
        }

        h1,
        h2,
        h3,
        h4,
        p {
            > a {
                font-size: inherit;
            }
        }
    }

    &-layout-html-block-column {
        .widget-html-block {
            margin-bottom: $widget-html-block__margin-bottom;

            &.text-indentation-enabled {
                margin-bottom: $widget-html-block__margin-bottom;
            }
        }

        .experience-hsngassets-htmlblock {
            &:last-child {
                .widget-html-block,
                .widget-html-block.text-indentation-enabled {
                    margin-bottom: 0;
                }
            }
        }
    }

    // Instagram Feed
    // stylelint-disable selector-max-compound-selectors
    // stylelint-disable max-nesting-depth

    &-four-columns {
        .widget-hero-banner-container {
            .widget-banner-image-wrapper {
                img {
                    aspect-ratio: 341/450;
                }
            }
        }
    }

    &-three-columns {
        .widget-article-tile-image-wrapper {
            img {
                aspect-ratio: 204/91;
            }
        }
    }

    &-two-columns {
        .widget-hero-banner-container {
            .widget-banner-image-wrapper {
                &.large {
                    img {
                        aspect-ratio: 211/94;
                    }
                }
            }
        }
    }

    &-single-column {
        .widget-hero-banner-container {
            .widget-banner-image-wrapper {
                img {
                    aspect-ratio: 3/2;
                }
            }
        }
    }

    &-double-column {
        .widget-hero-banner-container {
            .widget-banner-image-wrapper {
                img {
                    aspect-ratio: 3/2;
                }
            }
        }
    }

    &-category-matrix {
        &-main-container {
            .widget-hero-banner-container {
                .widget-banner-image-wrapper {
                    img {
                        aspect-ratio: 362/255;
                    }
                }
            }
        }

        &-side-container,
        &-bottom-container {
            .widget-hero-banner-container {
                .widget-banner-image-wrapper {
                    img {
                        aspect-ratio: 177 / 164;
                    }
                }
            }
        }
    }
}

.triple-swiper {
    .widget-banner-button-wrapper {
        width: 100%;

        a {
            width: 100%;
        }
    }
}

.copy-to-clipboard-message {
    @include get-font('small');
    position: absolute;
    z-index: 9;
    top: -7.5rem;
    left: 0;
    display: none;
    width: 100%;
    padding: $base-padding * 1;
    background: $copy-to-clipboard-arrow--after__border-bottom-color;
    color: $black;

    .arrow {
        position: $copy-to-clipboard-arrow__position;
        bottom: $copy-to-clipboard-arrow__bottom;
        left: -$copy-to-clipboard-arrow__bottom/2;
        display: block;
        width: $copy-to-clipboard-arrow__width;
        height: $copy-to-clipboard-arrow__height;
        margin: $copy-to-clipboard-arrow__margin;
        transform: rotate(180deg);

        &::before {
            content: '';
            position: $copy-to-clipboard-arrow__position;
            top: 0;
            display: block;
            border-width: $copy-to-clipboard-arrow--before__border-width;
            border-style: $copy-to-clipboard-arrow--before__border-style;
            border-color: $copy-to-clipboard-arrow--before__border-color;
            border-bottom-color: $copy-to-clipboard-arrow--before__border-bottom-color;

        }

        &::after {
            content: '';
            position: $copy-to-clipboard-arrow__position;
            top: $copy-to-clipboard-arrow--after__bottom;
            display: block;
            border-width: $copy-to-clipboard-arrow--before__border-width;
            border-style: $copy-to-clipboard-arrow--before__border-style;
            border-color: $copy-to-clipboard-arrow--before__border-color;
            border-bottom-color: $copy-to-clipboard-arrow--after__border-bottom-color;
        }
    }
}

@import '../../screens/medium/components/widgets/assets';
@import '../../screens/large/components/widgets/assets';
@import '../../screens/medium-large/components/widgets/assets';
