// =============================================================================
//
//  Page Designer Widgets
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

// stylelint-disable selector-max-compound-selectors
// stylelint-disable max-nesting-depth

@media screen and (min-width: $desktop-width) and (max-width: $desktop-width-large) {
    .widget {

        // Hero Banner
    
        &-banner-label {
            @include get-font('micro');
            min-width: $widget-banner-label__min-width;
            padding: $widget-banner-label__padding;
        }
    
        &-banner-content-wrapper {
            &.banner-content-half-width {
                .widget-banner-text-headline,
                .widget-banner-text-description {
                    width: $widget-hero-banner-content-half__width;
                }
            }
        }

        &-banner-text-description {
            > * {
                margin: $widget-hero-banner-text-description__margin;
            }
        }
    
        &-banner-button {
            @include get-font('xsmall-small');
        }

        &-single-column {
            .widget-hero-banner-container.large {
                .widget-banner-content-wrapper-overlay {
                    z-index: 1;
                    top: 50%;
                    height: auto;
                    padding-top: 0;
                    padding-bottom: 0;
                    transform: translateY(-50%);
                }

                .widget-banner-button-wrapper {
                    margin-bottom: $base-padding * 3.3;                    
                }
            }
        } 

        // Hero Banner in Four Column Grid

        &-category-grid {
            .widget-banner-button {
                width: $widget-hero-banner-four-column-button__width--medium-large;
            }

            .widget-banner-text-headline {
                margin-top: $widget-hero-banner-four-column-text-headline__margin-top--medium-large;
            }

            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-four-column-content-wrapper__padding--medium-large;
            }
        }

        &-category-matrix {
            .widget-banner-content-wrapper-overlay {
                padding: $widget-hero-banner-category-matrix-content-wrapper__padding--medium-large;
            }
        }

        // Hero Banner Font Size

        &-category-matrix,
        &-category-grid,
        &-single-column .widget-hero-banner-container.small {

            // stylelint-disable selector-class-pattern
            .pd-font-size-22px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-24px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-25px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-26px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-30px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-32px {
                @include get-font('normal');
        
                > * {
                    @include get-font('normal');
                }
            }
        
            .pd-font-size-36px {
                @include get-font('normal');
                line-height: 1.1;
        
                > * {
                    @include get-font('normal');
                    line-height: 1.1;
                }
            }
        
            .pd-font-size-46px {
                @include get-font('normal');
                line-height: 1.1;
        
                > * {
                    @include get-font('normal');
                    line-height: 1.1;
                }
            }
        }
    }
}
