// =============================================================================
//
//  Page Designer Dynamic Options CSS
//
//  @version    0.0.1
//  @package    Bodyman
//  @author     Jovan Poplasen <jovan@improove.se>
//
//==============================================================================

@import '../../themes/default/components/widgets/layout';
@import '../../themes/default/components/widgets/assets';

// Page designer dynamic colors

.pd-color-white {
    color: $white !important;
}

.pd-color-black {
    color: $black !important;
}

.pd-color-blackout {
    color: $blackout !important;
}

.pd-color-orange {
    color: $orange !important;
}

.pd-color-orange-dash {
    color: $orange-dash !important;
}

.pd-color-red-signal {
    color: $red-signal !important;
}

.pd-color-ice-blue {
    color: $ice-blue !important;
}

.pd-color-dark-blue {
    color: $dark-blue !important;
}

.pd-color-grey {
    color: $grey !important;
}

.pd-color-light-grey {
    color: $light-grey !important;
}

// Page designer dynamic colors mobile

.pd-mobile-color-white {
    color: $white !important;
}

.pd-mobile-color-black {
    color: $black !important;
}

.pd-mobile-color-blackout {
    color: $blackout !important;
}

.pd-mobile-color-orange {
    color: $orange !important;
}

.pd-mobile-color-orange-dash {
    color: $orange-dash !important;
}

.pd-mobile-color-red-signal {
    color: $red-signal !important;
}

.pd-mobile-color-ice-blue {
    color: $ice-blue !important;
}

.pd-mobile-color-dark-blue {
    color: $dark-blue !important;
}

.pd-mobile-color-grey {
    color: $grey !important;
}

.pd-mobile-color-light-grey {
    color: $light-grey !important;
}

// Page designer dynamic background colors

.pd-background-white {
    background-color: $white !important;
}

.pd-background-black {
    background-color: $black !important;
}

.pd-background-blackout {
    background-color: $blackout !important;
}

.pd-background-orange {
    background-color: $orange !important;
}

.pd-background-orange-dash {
    background-color: $orange-dash !important;
}

.pd-background-red-signal {
    background-color: $red-signal !important;
}

.pd-background-ice-blue {
    background-color: $ice-blue !important;
}

.pd-background-dark-blue {
    background-color: $dark-blue !important;
}

.pd-background-grey {
    background-color: $grey !important;
}

.pd-background-light-grey {
    background-color: $light-grey !important;
}

// Page Designer Dynimic Font Sizes for Mobile

// stylelint-disable selector-class-pattern

.pd-font-size-11px:not(.widget-html-block) {
    @include get-font('tiny');

    > * {
        @include get-font('tiny');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-12px:not(.widget-html-block) {
    @include get-font('mini');

    > * {
        @include get-font('mini');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-13px:not(.widget-html-block) {
    @include get-font('mini-xsmall');

    > * {
        @include get-font('mini-xsmall');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-14px:not(.widget-html-block) {
    @include get-font('xsmall');

    > * {
        @include get-font('xsmall');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-15px:not(.widget-html-block) {
    @include get-font('xsmall-small');

    > * {
        @include get-font('xsmall-small');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-16px:not(.widget-html-block) {
    @include get-font('small');

    > * {
        @include get-font('small');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-18px:not(.widget-html-block) {
    @include get-font('compact');

    > * {
        @include get-font('compact');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.pd-font-size-20px:not(.widget-html-block) {
    @include get-font('normal');

    > * {
        @include get-font('normal');
    }

    > p,
    ul,
    ol {
        line-height: $widget_hmtl_p__line-height;
    }
}

.widget {
    &-html-block {
        &.pd-color-white {
            * {
                color: $white !important;
            }
        }

        &.pd-color-black {
            * {
                color: $black !important;
            }
        }

        &.pd-color-blackout {
            * {
                color: $blackout !important;
            }
        }

        &.pd-color-orange {
            * {
                color: $orange !important;
            }
        }

        &.pd-color-orange-dash {
            * {
                color: $orange-dash !important;
            }
        }

        &.pd-color-red-signal {
            * {
                color: $red-signal !important;
            }
        }

        &.pd-color-grey {
            * {
                color: $grey !important;
            }
        }

        &.pd-color-light-grey {
            * {
                color: $light-grey !important;
            }
        }

        &.pd-color-ice-blue {
            * {
                color: $ice-blue !important;
            }
        }

        &.pd-color-dark-blue {
            * {
                color: $dark-blue !important;
            }
        }

        &.pd-font-size-11px {
            > p,
            ul,
            ol {
                @include get-font('tiny');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-12px {
            > p,
            ul,
            ol {
                @include get-font('mini');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-13px {
            > p,
            ul,
            ol {
                @include get-font('mini-xsmall');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-14px {
            > p,
            ul,
            ol {
                @include get-font('xsmall');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-15px {
            > p,
            ul,
            ol {
                @include get-font('xsmall-small');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-16px {
            > p,
            ul,
            ol {
                @include get-font('small');
                line-height: $widget_hmtl_p__line-height;
            }
        }

        &.pd-font-size-18px {
            > p,
            ul,
            ol {
                @include get-font('compact');
            }
        }

        &.pd-font-size-20px {
            > p,
            ul,
            ol {
                @include get-font('normal');
                line-height: $widget_hmtl_p__line-height;
            }
        }
    }

    // Goal Banner Diagonal Backgrounds

    &-goal-banner-background-wrapper {
        &.pd-diagonal-background-white {
            .pd-diagonal-background-white {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $white;
                background-image: linear-gradient($widget-goal-banner-diagonal, $white $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-black {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $black;
                background-image: linear-gradient($widget-goal-banner-diagonal, $black $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-blackout {
            .pd-diagonal-background-white {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $blackout;
                background-image: linear-gradient($widget-goal-banner-diagonal, $blackout $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-orange {
            .pd-diagonal-background-white {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $orange;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-orange-dash {
            .pd-diagonal-background-white {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-goal-banner-diagonal, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-red-signal {
            .pd-diagonal-background-white {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $red-signal;
                background-image: linear-gradient($widget-goal-banner-diagonal, $red-signal $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-grey {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $grey $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-light-grey {
            .pd-diagonal-background-white {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $light-grey;
                background-image: linear-gradient($widget-goal-banner-diagonal, $light-grey $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-ice-blue {
            .pd-diagonal-background-white {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-dark-blue {
            .pd-diagonal-background-white {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $white $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $black $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $blackout $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $orange $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $orange-dash $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $red-signal $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $light-grey $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $ice-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-goal-banner-diagonal, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__first, $dark-blue $widget-layout-goal-banner-gradient-background-percentage__second);
            }
        }
    }

    // Category Grid Diagonal Backgrounds

    &-layout-category-grid {
        &.pd-diagonal-background-white {
            .pd-diagonal-background-white {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $white;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $white $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-black {
            .pd-diagonal-background-white {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $black;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $black $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-blackout {
            .pd-diagonal-background-white {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $blackout;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $blackout $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-orange {
            .pd-diagonal-background-white {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $orange;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-orange-dash {
            .pd-diagonal-background-white {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $orange-dash;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $orange-dash $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-red-signal {
            .pd-diagonal-background-white {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $red-signal;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $red-signal $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-grey {
            .pd-diagonal-background-white {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $grey $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-light-grey {
            .pd-diagonal-background-white {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $light-grey;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $light-grey $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-ice-blue {
            .pd-diagonal-background-white {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $ice-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $ice-blue $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }

        &.pd-diagonal-background-dark-blue {
            .pd-diagonal-background-white {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $white $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-black {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $black $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-blackout {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $blackout $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $orange $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-orange-dash {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $orange-dash $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-red-signal {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $red-signal $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-grey {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-light-grey {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $light-grey $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-ice-blue {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $ice-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }

            .pd-diagonal-background-dark-blue {
                background-color: $dark-blue;
                background-image: linear-gradient($widget-layout-category-grid-diagonal, $dark-blue $widget-layout-category-grid-gradient-background-percentage__first, $dark-blue $widget-layout-category-grid-gradient-background-percentage__second);
            }
        }
    }
}

@import '../../screens/large/components/widgets/pageDesignerDynamic';
